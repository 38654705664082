import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import nprogress from 'nprogress';
import styled from 'styled-components';

import routes, { PATHS } from './routesConfig';
import Navigation from 'src/components/Navigation';
import Footer from 'src/components/Footer';

const StyledLoader = styled.div`
  height: calc(100vh + 64px);
`;

const Loader: React.FC = props => {
  useEffect(() => {
    nprogress.start();
    setTimeout(nprogress.done, 300);
  }, []);
  return <StyledLoader />;
};

const AppRoutes = () => {
  return (
    <Router>
      <Navigation />
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}
          <Redirect to={PATHS.Home} />
        </Switch>
      </Suspense>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
