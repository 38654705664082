import { layout, flexbox, space } from 'styled-system';
import styled from 'styled-components';

const StyledAnchor: any = styled.a`
  ${layout}
  ${flexbox}
  ${space}
  text-decoration: none;
  cursor: pointer;
`;

export default StyledAnchor;
