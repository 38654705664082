import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const Join = React.lazy(() => import('../pages/Partner'));
const Faqs = React.lazy(() => import('../pages/Faqs'));
const Terms = React.lazy(() => import('../pages/Terms'));

export const PATHS = {
  Home: '/',
  Join: '/join',
  Terms: '/terms',
  Faqs: '/faqs',
};

export default [
  {
    path: PATHS.Home,
    component: Home,
    private: false,
    exact: true,
  },
  {
    path: PATHS.Join,
    component: Join,
    private: false,
    exact: true,
  },
  {
    path: PATHS.Faqs,
    component: Faqs,
    private: false,
    exact: true,
  },
  {
    path: PATHS.Terms,
    component: Terms,
    private: false,
    exact: true,
  },
];
