import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Popup from 'reactjs-popup';

import { STFlex } from 'design/atoms/Container';
import { STNavItem } from 'design/atoms/NavItem';
import { Box } from 'design/atoms/Box';
import { BaseText } from 'design/atoms/Text';
import { STButton } from 'design/atoms/Button';
import { STIcon } from 'design/atoms/Icon';
import { STNavbar, STNavItemsGroup } from 'design/molecules/Navigation';
import { useMedia } from 'src/shared/hooks/useMedia';
import { StyledLink } from 'src/shared/StyledLink';
import StyledAnchor from 'src/shared/StyledAnchor';

import { PATHS } from 'src/routes/routesConfig';
import logo from 'src/static/images/sasta-logo.svg';
import content from 'src/content/shared.json';
import { analytics } from 'src/utils/firebase';

const links = [
  {
    path: 'https://www.sastaticket.pk/',
    name: content.navbar.flights,
  },
  {
    path: 'https://www.sastaticket.pk/hotels.aspx',
    name: content.navbar.hotels,
  },
  {
    path: 'https://visa.sastaticket.pk/',
    name: content.navbar.visas,
  },
  {
    path: 'https://groups.sastaticket.pk/',
    name: content.navbar.groups,
  },
];

const StyledNavMenuButton = styled.button`
  background: none;
  border: none;
  outline: none;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
`;

const PopupLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  display: block;
  color: #484848;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
`;

const NavigationPopup = ({ pathname }) => {
  const isHomeActive = pathname.length === 1 && pathname.includes(PATHS.Home);
  return (
    <STFlex
      flexDirection="column"
      bg="white"
      boxShadow={0}
      width={230}
      borderRadius={1}
      p={3}
    >
      <Box p={1}>
        <StyledLink to={PATHS.Home}>
          <BaseText
            fontWeight={isHomeActive ? 700 : 400}
            fontSize={16}
            mb={2}
            color={isHomeActive ? '#006FAE' : '#484848'}
          >
            {content.navbar.home}
          </BaseText>
          {/* <STNavItem
            active={pathname.length > 0 && pathname.includes(PATHS.Home)}
            label={content.navbar.home}
          /> */}
        </StyledLink>
      </Box>
      {links.map(link => (
        <Box key={link.name} p={1}>
          <PopupLink href={link.path}>{link.name}</PopupLink>
        </Box>
      ))}
      {['/', '/faqs'].includes(pathname) && (
        <StyledLink
          onClick={() => {
            analytics.logEvent('button_click', {
              name: 'Navbar button',
            });
          }}
          width={150}
          mx="auto"
          my={2}
          to="/join"
        >
          <STButton height={36} fontWeight={500} pt="8px">
            {content.navbar.button}
          </STButton>
        </StyledLink>
      )}
    </STFlex>
  );
};

const Navigation = props => {
  const {
    location: { pathname },
  } = props;
  const matches = useMedia();

  return (
    <>
      <STFlex
        as="header"
        position="absolute"
        zIndex={2}
        width="100%"
        bg="white"
        height={64}
      >
        <STFlex
          justifyContent="flex-start"
          alignItems="center"
          px={[3, 3]}
          width="100%"
          boxShadow={0}
        >
          <STNavbar width={['100%', 1140]} mx="auto">
            <StyledAnchor
              mx={matches.large ? 0 : 'auto'}
              mr={matches.large ? 60 : 'auto'}
              my="auto"
              href="https://sastaticket.pk/"
            >
              <Box
                width={['146px', '160px']}
                height={['30px', '32px']}
                my="auto"
                left={0}
                right={0}
                as="img"
                src={logo}
              />
            </StyledAnchor>

            {matches.large ? (
              <>
                <STNavItemsGroup>
                  <Box ml={[0, 4]}>
                    <StyledLink to={PATHS.Home}>
                      <STNavItem
                        active={
                          pathname.includes(PATHS.Home) && pathname.length === 1
                        }
                        label={content.navbar.home}
                        onClick={() => {}}
                        fontFamily="Montserrat"
                      />
                    </StyledLink>
                  </Box>
                  {links.map(link => (
                    <Box key={link.name} ml={[0, 4]}>
                      <a
                        href={link.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'contents',
                          textDecoration: 'none',
                        }}
                      >
                        <STNavItem label={link.name} fontFamily="Montserrat" />
                      </a>
                    </Box>
                  ))}
                </STNavItemsGroup>

                {/* Right Items Group */}
                <STNavItemsGroup align="right">
                  {['/', '/faqs'].includes(pathname) && (
                    <StyledLink
                      onClick={() => {
                        analytics.logEvent('button_click', {
                          name: 'Navbar button',
                        });
                      }}
                      my="auto"
                      to="/join"
                    >
                      <STButton height={36} fontWeight={500} pt="8px">
                        {content.navbar.button}
                      </STButton>
                    </StyledLink>
                  )}
                </STNavItemsGroup>
              </>
            ) : (
              <Popup
                trigger={
                  <StyledNavMenuButton>
                    <STIcon cursor="pointer" icon="MoreVertical" />
                  </StyledNavMenuButton>
                }
                position="bottom right"
                arrow={false}
              >
                <NavigationPopup pathname={pathname} />
              </Popup>
            )}
          </STNavbar>
        </STFlex>
      </STFlex>
    </>
  );
};

export default withRouter(Navigation);
