import React from 'react';
import ReactDOM from 'react-dom';
import 'nprogress/nprogress.css';

import './styles/fonts.css';
import AppProviders from './AppProviders';
import GlobalStyles from './styles/GlobalStyles';
import App from 'src/App';

ReactDOM.render(
  <AppProviders>
    <GlobalStyles />
    <App />
  </AppProviders>,
  document.getElementById('root')
);