import React from 'react';

import logo from 'src/static/images/sasta-logo.svg';
import { Box } from 'design/atoms/Box';
import { STFlex } from 'design/atoms/Container';
import { BaseText } from 'design/atoms/Text';
import StyledAnchor from 'src/shared/StyledAnchor';
import { ReactComponent as Facebook } from 'src/static/images/facebook.svg';
import { ReactComponent as LinkedIn } from 'src/static/images/linkedin.svg';
import { ReactComponent as Twitter } from 'src/static/images/twitter.svg';
import content from 'src/content/shared.json';
import { useMedia } from 'src/shared/hooks/useMedia';

const SocialLink = ({ icon, name, href }) => {
  const matches = useMedia();
  return (
    <StyledAnchor
      display="flex"
      alignItems="center"
      href={href}
      width="content-fit"
    >
      {icon}
      {matches.medium ? (
        <BaseText
          color="white"
          fontSize={16}
          textDecoration="none"
          fontFamily="Montserrat"
          height={name === 'LinkedIn' ? '14px' : '100%'}
          ml={3}
        >
          {name}
        </BaseText>
      ) : null}
    </StyledAnchor>
  );
};

const Footer = () => {
  return (
    <Box as="section" bg="#003769" zIndex={10000}>
      <STFlex
        flexDirection={'column'}
        justifyContent={['space-between', 'flex-start']}
        width="100%"
      >
        <STFlex
          px={[4, 7]}
          height={[360, 225]}
          justifyContent={['center', 'space-between']}
          flexDirection={['column', 'row']}
        >
          <STFlex
            width={['100%', '50%']}
            alignItems="center"
            justifyContent={['center', 'flex-start']}
            mb={[5, 0]}
          >
            <Box as="img" src={logo} width={200} />
          </STFlex>
          <STFlex
            width={['100%', '50%']}
            py={[0, 5]}
            flexDirection={['column', 'row']}
          >
            <STFlex
              width={['100%', '50%']}
              flexDirection={['row', 'column']}
              height="100%"
              justifyContent={['space-around', 'space-between']}
              py={2}
              mb={[5, 0]}
            >
              <StyledAnchor width="fit-content" href="/">
                <BaseText
                  color="white"
                  fontSize={16}
                  textDecoration="none"
                  fontFamily="Montserrat"
                >
                  {content.footer.link1}
                </BaseText>
              </StyledAnchor>
              <StyledAnchor width="fit-content" href="/faqs">
                <BaseText
                  color="white"
                  fontSize={16}
                  textDecoration="none"
                  fontFamily="Montserrat"
                >
                  {content.footer.link2}
                </BaseText>
              </StyledAnchor>
              <StyledAnchor width="fit-content" href="/terms">
                <BaseText
                  color="white"
                  fontSize={16}
                  textDecoration="none"
                  fontFamily="Montserrat"
                >
                  {content.footer.link3}
                </BaseText>
              </StyledAnchor>
            </STFlex>

            <STFlex
              width={['50%', '50%']}
              flexDirection={['row', 'column']}
              height="100%"
              justifyContent={['space-around', 'space-between']}
              py={2}
              mx={['auto', 0]}
            >
              <SocialLink
                name={content.footer.socialLink1}
                icon={<Facebook />}
                href="https://www.facebook.com/sastaticket.pk"
              />
              <SocialLink
                name={content.footer.socialLink2}
                icon={<Twitter />}
                href="https://twitter.com/sastaticketpk"
              />
              <SocialLink
                name={content.footer.socialLink3}
                icon={<LinkedIn />}
                href="https://www.linkedin.com/company/sastaticket.pk/"
              />
            </STFlex>
          </STFlex>
        </STFlex>

        <STFlex
          borderTop="1px solid rgba(255, 255, 255, 0.3)"
          justifyContent="center"
          alignItems="center"
          py={3}
        >
          <BaseText
            py={2}
            color="white"
            opacity={0.9}
            fontFamily="Montserrat"
            fontSize={[12, 15]}
            textAlign="center"
          >
            &copy; {content.footer.copyright}
          </BaseText>
        </STFlex>
      </STFlex>
    </Box>
  );
};

export default Footer;
