// @ts-nocheck
import React from 'react';
import { STFlex } from 'design/atoms/Container';

interface NavbarProps {
  children: React.ReactNode | React.ReactNode[];
}

export const STNavbar = ({ children, ...props }: any) => {
  return (
    <STFlex height="100%" width="100%" justifyContent="flex-start" {...props}>
      {children}
    </STFlex>
  );
};
