import { useMedia as reactUseMedia } from 'react-media';

const GLOBAL_MEDIA_QUERIES = {
  small: '(min-width: 300px)',
  medium: '(min-width: 500px)',
  large: '(min-width: 900px)',
  xLarge: '(min-width: 1100px)',
  xxLarge: '(min-width: 1400px)',
};

export function useMedia() {
  return reactUseMedia({ queries: GLOBAL_MEDIA_QUERIES });
}
