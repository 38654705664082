import styled from 'styled-components';
import { layout, space, LayoutProps, SpaceProps } from 'styled-system';
import { Link, LinkProps } from 'react-router-dom';

export const StyledLink = styled(Link)<
  LinkProps &
    LayoutProps &
    SpaceProps & {
      color?: string;
      fontSize?: number;
      weight?: string;
      fontFamily?: string;
      onClick?: () => void;
    }
>`
  ${layout}
  ${space}
  color: ${({ color }) => color || '#000'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : 'inherit')};
  font-weight: ${({ weight }) => weight || 'initial'};
  text-decoration: none;
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
`;
